import React, { Component } from "react";
import Slider from "react-slick";
import fynd from "../assets/img/fund1.png";
import flipkart from "../assets/img/flipkart.png";
import meesho from "../assets/img/Meesho.jpg";
import AlliedServices from "../assets/img/business/AlliedServicesLebenswerkBusinessManagement1.png";
import BusinessConsulting from "../assets/img/business/BusinessConsultingLebenswerkBusinessManagement1.png";
import EcommerceServices from "../assets/img/business/EcommerceServicesLebenswerkBusinessManagement1.png";
import HRServices from "../assets/img/business/HRServicesLebenswerkBusinessManagement.png";
import cloudtail from "../assets/img/cloudtail.png";
import nyka from "../assets/img/nykaa.png";
import tatacliq from "../assets/img/tata-cliq.png";

export default class SliderBanner extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      speed: 3000,
      autoplaySpeed: 4000,
      cssEase: "linear",
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <div>
        <Slider {...settings}>
          <div
            className="sliderBanner align-middle"
            style="align-items: center;"
          >
            <img src={EcommerceServices} alt="mall91" />
          </div>
          <div
            className="sliderBanner align-middle"
            style="align-items: center;"
          >
            <img src={BusinessConsulting} alt="flipkart" />
          </div>
          <div
            className="sliderBanner align-middle"
            style="align-items: center;"
          >
            <img src={AlliedServices} alt="tatacliq" />
          </div>
          <div
            className="sliderBanner align-middle"
            style="align-items: center;"
          >
            <img src={HRServices} alt="cloudtail" />
          </div>
          {/* <div className="sliderBanner align-middle" style="align-items: center;">
            <img src={nyka} alt="nyka" />
          </div>
          <div className="sliderBanner align-middle" style="align-items: center;">
            <img src={meesho} alt="meesho" />
          </div>
          <div className="sliderBanner align-middle" style="align-items: center;">
            <img src={fynd} alt="fynd" />
          </div> */}
        </Slider>
      </div>
    );
  }
}
